<script>
import SearchAndActions from "@/components/Table/SearchAndActions.vue";
import Header from "@/components/Table/Header.vue";
import axios from "axios";
import DataTable from "@/components/Table/DataTable.vue";
import ActionsButton from "@/components/Table/ActionsButton.vue";
import TablePagination from "@/components/Table/TablePagination.vue";
import CreateArea from "@/components/areas/CreateArea.vue";
import EditArea from "@/components/areas/EditArea.vue";
import {mapGetters} from "vuex";

export default {
  name: "Areas",
  components: {EditArea, CreateArea, TablePagination, ActionsButton, DataTable, Header, SearchAndActions},
  data() {
    return {
      breadcrumbs: [{text: "Home", path: "/dashboard", isActive: false}, {
        text: "Areas",
        path: "/areas",
        isActive: true
      }],
      tableData: [],
      pagination: {
        current_page: 1,
        per_page: 10,
        total: 0,
        last_page: 0,
      },
      search: null,
      sort: '-created_at',
      date: null,

      area: {
        id: 0,
        name: ""
      },
      createAreaModal: false,
      editAreaModal: false,
    }
  },
  computed: {
    ...mapGetters("RSCode", ["rscodeLists"]),
  },
  created() {
    this.$store.dispatch("Config/setBreadCrumb", this.breadcrumbs);
  },
  mounted() {
    let page = this.$route.query.page ? this.$route.query.page : 1;
    let per_page = this.$route.query.per_page ? this.$route.query.per_page : 10;
    let search = this.$route.query.search ? this.$route.query.search : null;
    let sort = this.$route.query.sort ? this.$route.query.sort : '-created_at';
    let date = this.$route.query.date ? this.$route.query.date : null;

    this.getListData(page, per_page, search, sort, date);

    this.$store.dispatch("RSCode/getRSCodeLists");
  },
  methods: {
    getListData(page = 1, per_page = 10, search = null, sort = '-created_at', date = null) {
      let url = '/admin/areas-list';
      let params = {
        page: page,
        per_page: per_page ? per_page : this.pagination.per_page,
        search: search ? search : this.search,
        sort: sort,
        date: date ? date : this.date,
      };

      axios.get(url, {params})
          .then(response => {
            this.tableData = response.data.data;
            this.pagination = response.data.meta;
            this.sort = sort;

            this.$router.replace({query: params});
          })
    },
    onPageChange(page) {
      this.getListData(page, this.pagination.per_page, this.search, this.sort, this.date);
    },
    onPageSizeChanged(pageSize) {
      this.getListData(1, pageSize, this.search, this.sort, this.date);
    },
    onSearch(search) {
      this.search = search ? search : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date);
    },
    sortBy(field) {
      let sort = this.sort === field ? '-' + field : field;
      this.getListData(1, this.pagination.per_page, this.search, sort, this.date);
    },
    onDate(date) {
      this.date = date ? date.join(',') : null;
      this.getListData(1, this.pagination.per_page, this.search, this.sort, this.date);
    },
    dismissCreateAreaModal() {
      this.getListData()
      this.createAreaModal = false;
    },
    dismissEditAreaModal() {
      this.getListData()
      this.editAreaModal = false;
    },
    editArea(area) {
      this.area = area;
      this.editAreaModal = true;
    },
    destroy(area) {
      this.$confirm({
        message: `Are you sure, want to delete this Area?`,
        button: {
          no: "No",
          yes: "Yes, Delete",
        },
        callback: (confirm) => {
          if (confirm) {
            this.$store.dispatch("Areas/destroy", area.id).then(
                () => {
                  this.$store.dispatch("Settings/loading", false);
                  this.$toastr.s("Area successfully deleted.", "Deleted!");
                },
                (error) => {
                  this.$toastr.e(error.response.data.message, "Failed!");
                  this.$store.dispatch("Settings/loading", false);
                }
            );
          }
        },
      });
    },
  }
}
</script>

<template>
  <div>
    <div class="row">
      <div class="col-md-12">
        <h6 class="text-uppercase font-weight-bold text-dark">Areas</h6>
      </div>

      <div class="col-md-12">
        <div class="card p-4">
          <SearchAndActions
              :show-date="false"
              :show-import="false"
              @search="onSearch"
              @date="onDate"
              action-url="/admin/areas-list"
              download-file-name="areas"
          >
            <el-button
                v-if="checkUsrPermissions(['Area_all', 'Area_create'])"
                type="primary"
                @click="createAreaModal = true"
                size="small"
                class="ml-2"
                icon="el-icon-plus"
            >
              Add New
            </el-button>
          </SearchAndActions>

          <div class="row">
            <div class="col-md-12">
              <DataTable>
                <template #header>
                  <Header :sort="false" title="Sl"/>
                  <Header active="area_code" :ordering="sort" @sortBy="sortBy('area_code')" title="Area Code"/>
                  <Header active="name" :ordering="sort" @sortBy="sortBy('name')" title="Area Name"/>
                  <Header active="opr_name" :ordering="sort" @sortBy="sortBy('opr_name')" title="Opr Name"/>
                  <Header active="revenue_officer_id" :ordering="sort" @sortBy="sortBy('revenue_officer_id')" title="RO Code"/>
                  <Header active="revenue_officer_id" :ordering="sort" @sortBy="sortBy('revenue_officer_id')" title="RO Name"/>
                  <Header active="revenue_supervisor_id" :ordering="sort" @sortBy="sortBy('revenue_supervisor_id')" title="RS Code"/>
                  <Header active="revenue_supervisor_id" :ordering="sort" @sortBy="sortBy('revenue_supervisor_id')" title="RS Name"/>
                  <Header
                      :sort="false"
                      title="Actions"
                      v-if="
                          checkUsrPermissions([
                            'Area_all',
                            'Area_edit'
                          ])
                        "
                  />
                </template>

                <tr
                    v-for="(data, index) in tableData"
                    :key="data.id"
                >
                  <th scope="row">
                    {{ pagination.from + index }}
                  </th>
                  <td>{{ data.area_code }}</td>
                  <td>
                    {{ data.name }}
                  </td>
                  <td>
                    {{ data.opr_name }}
                  </td>
                  <td>
                    {{ data.revenue_officer && data.revenue_officer.ro_code }}
                  </td>
                  <td>
                    {{ data.revenue_officer && data.revenue_officer.ro_name }}
                  </td>
                  <td>
                    {{ data.revenue_supervisor && data.revenue_supervisor.rs_code }}
                  </td>
                  <td>
                    {{ data.revenue_supervisor && data.revenue_supervisor.rs_name }}
                  </td>
                  <td>
                    <ActionsButton>
                      <a
                          v-if="checkUsrPermissions(['Area_all', 'Area_create'])"
                          href="#"
                          @click="editArea(data)"
                      >
                        Edit
                      </a>
                      <a
                          v-if="checkUsrPermissions(['Area_all', 'Area_delete'])"
                          href="#"
                          @click="destroy(data)"
                      >
                        Delete
                      </a>
                    </ActionsButton>
                  </td>
                </tr>
              </DataTable>

              <div class="mt-5" v-if="tableData.length">
                <TablePagination
                    :pagination="pagination"
                    @pagechanged="onPageChange"
                    @pagesizechanged="onPageSizeChanged"
                />
              </div>

              <div v-else>
                <p class="text-center font-weight-bold">
                  No data found
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <CModal
        v-if="createAreaModal"
        :closeOnBackdrop="false"
        title="Add Area"
        color="primary"
        class="hide-footer custom-close-button"
        :show.sync="createAreaModal"
    >
      <CreateArea :rscodeLists="rscodeLists" @onSavedArea="dismissCreateAreaModal"></CreateArea>
    </CModal>

    <CModal
        v-if="editAreaModal"
        :closeOnBackdrop="false"
        title="Edit Area"
        color="primary"
        class="hide-footer custom-close-button"
        :show.sync="editAreaModal"
    >
      <EditArea :rscodeLists="rscodeLists" @onEditedArea="dismissEditAreaModal" :area="area"></EditArea>
    </CModal>
  </div>
</template>