<template>
  <CRow>
    <CCol>
      <div v-if="serverErrors.length">
        <CAlert v-model="showDismissibleAlert" color="warning" closeButton>
          <ul>
            <li v-for="err in serverErrors" :key="err">{{ err }}</li>
          </ul>
        </CAlert>
      </div>
      <form v-on:submit.prevent="onSubmitted">
        <div class="row">
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Area Code</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="edited_area.area_code"
                  placeholder="Enter Area Code"
                  required
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Area name</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="edited_area.name"
                  placeholder="Enter area name"
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>Opr name</label>
              <input
                  type="text"
                  class="form-control"
                  v-model="edited_area.opr_name"
                  placeholder="Enter opr name"
              />
            </div>
          </div>
          <div class="col-md-12 col-12">
            <div class="form-group">
              <label>RS Code</label>
              <select class="form-control" v-model="edited_area.revenue_supervisor_id" id="">
                <option v-for="rscodeList in rscodeLists" :value="rscodeList.id">{{ rscodeList.rs_code }}</option>
              </select>
            </div>
          </div>
        </div>

        <button type="submit" class="btn btn-primary">Update</button>
      </form>
    </CCol>
  </CRow>
</template>

<script>

export default {
  name: "EditArea",
  props: {
    area: Object,
    rscodeLists: {
      type: Array,
      default: []
    }
  },
  data: () => {
    return {
      toastr: "",
      showToast: false,
      edited_area: {
        id: 0,
        name: "",
        opr_name: "",
        revenue_supervisor_id: "",
      },
      serverErrors: [],
      showDismissibleAlert: false,
    };
  },
  methods: {
    onSubmitted() {
      this.$store
          .dispatch("Areas/updateArea", this.edited_area)
          .then(() => {
            this.showToast = true;
            this.$toastr.s('success', "Area successfully updated.", "Area Updated");
            setTimeout(() => {
              this.$emit("onEditedArea");
            }, 350);
          })
          .catch((err) => {
            this.showDismissibleAlert = true;
            this.serverErrors = [];
            if (err.response.data.message) {
              this.serverErrors.push(err.response.data.message);
            }

            let errors = err.response.data.errors;
            for (let field of Object.keys(errors)) {
              this.showToast = true;
              this.toastr.e("error, " + errors[field][0] + ", Error!");
            }
          });
    },
  },
  mounted() {
    this.edited_area = { ...this.area };
  },
};
</script>

<style scoped>
</style>
